window.__tnt || (window.__tnt = {});
window.__tnt.subscription || (__tnt.subscription = {});
window.__tnt.subscription.access || (__tnt.subscription.access = []);

window.__tnt.subscription.access.status || (__tnt.subscription.access.status = {});

window.__tnt.subscription.access.status.trigger = function(userStatus){
    if(!__tnt.subscription.access.status.data){
            window.dispatchEvent(userStatus);
            return true;
    }else{
        return false;
    }
}

window.__tnt.subscription.access.status.check = function(callback){
    if(__tnt.subscription.access.status.data){
        callback(__tnt.subscription.access.status.data);

    }else{
        window.addEventListener('tnt-user-status',function(ev){
            callback({
                'version':ev.version,
                'hasAccess':ev.hasAccess,
                'accessMethod':ev.accessMethod,
                'accessError':ev.accessError
            });
        });        
    }
}

window.addEventListener('tnt-user-status',function(ev){
    __tnt.subscription.access.status.data = {
        'version':ev.version,
        'hasAccess':ev.hasAccess,
        'accessMethod':ev.accessMethod,
        'accessError':ev.accessError
    }
});

